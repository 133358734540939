import useJwt from '@/auth/jwt/useJwt'
import { API_ROUTES } from '../config/routes/api'

export default {
	async getAll() {
		const params = {
			order: 'asc',
			order_by: 'name',
			offset: 0,
			limit: 50,
		}
		const { data } = await useJwt.get(API_ROUTES.client.get, params)

		return data
	},
	async find(id) {
		const { data } = await useJwt.get(`${API_ROUTES.client.find}/${id}`)

		return data
	},
	save(id, data) {
		return useJwt.post(`${API_ROUTES.client.save}/${id}`, data)
	},
	delete(id) {
		return useJwt.delete(`${API_ROUTES.client.delete}/${id}`)
	},
	update(id, data) {
		const url = API_ROUTES.client.update.replace(':id', id)
		return useJwt.post(url, data)
	},

	async getAllCountry() {
		const params = {
			order: 'asc',
			order_by: 'name',
			offset: 0,
			limit: 0,
			'filters[0][field]': 'id',
			'filters[0][operator]': '=',
			'filters[0][value]': 1,
		}
		const { data } = await useJwt.get(API_ROUTES.country.get, params)

		return data
	},
	async getAllDepartament() {
		const params = {
			order: 'asc',
			order_by: 'id',
			offset: 0,
			limit: 200,
			'filters[0][field]': 'country_id',
			'filters[0][operator]': '=',
			'filters[0][value]': 1,
		}
		const { data } = await useJwt.get(API_ROUTES.departament.get, params)

		return data
	},
	async getAllCitiesByDepartament(departamentId) {
		const params = {
			order: 'asc',
			order_by: 'id',
			offset: 0,
			limit: 200,
			'filters[0][field]': 'department_id',
			'filters[0][operator]': '=',
			'filters[0][value]': departamentId,
		}
		const { data } = await useJwt.get(API_ROUTES.city.get, params)

		return data
	},
}
